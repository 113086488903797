import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
	Card, CardBody, Table
} from "reactstrap";
import {
	BASE_URL,
	IMAGE_URL,
} from "../../constants/defaultValues";

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import moment from 'moment';

import { zeroPad, getCurrencySymbol } from "../../helpers/Utils";
import number_format from "number_format-php";
import styles from "./InvoiceModalContent.module.css";

export default class InvoiceModalContent extends Component {

	render() {
		const { order_data, order_products, facility_data, currencies } = this.props;
		var current_currency_symbol = getCurrencySymbol();
		var sales_tax = 0;
		var sgst_tax = 0;
		var cgst_tax = 0;
		var sub_total = 0;
		var p_discount = 0;
		let is_utgst = false;
		let is_igst = true;
		if (order_data != null) {
			var current_currency = currencies.find(row => { return row.currency_code == order_data.currency_code });
			if (current_currency != null) {
				//current_currency_symbol = current_currency.currency_symbol;
			}

			/*is_igst = order_data.customer_state_id !== facility_data.facility_state_id;
			if(!is_igst){
				is_utgst = order_data.is_utgst=="1";
			}*/
		}
		var default_logo;
		return (

			<Row className="invoice-react">
				<Colxx xxs="12" className="">
					{facility_data != null ? (facility_data.facility_logo != null && facility_data.facility_mobile_logo != null) ?
						default_logo = <div className="mb-0 d-flex  justify-content-center " >
							<a href="/">
								<img src={BASE_URL + 'pages/' + facility_data.facility_logo} className="mb-0" />
							</a>
						</div>
						: "" : ""}
					<div className="mb-5 invoice-contents">
						<CardBody className="d-flex flex-column justify-content-between">
							<div className="d-flex flex-column">
								<div className={`d-flex flex-row justify-content-between pt-2 border-bottom`}>
									<div className="">
										    <img src={`${IMAGE_URL}assets/img/logo-black-3.png`} width={100} height={100} />
									</div>
									{order_data!=null && parseInt(order_data?.status)!=3 ? (
										<div>
											<div className="font-weight-bold text-uppercase h4">Quotation</div>
											<p className="mb-5">
												QUOTATION # {zeroPad(order_data.order_number, 6)}
												<br />
												DATE: {moment(order_data.timestamp * 1000).format("MMMM DD, YYYY")}
												{/* {order_data.payment_mode !== 0 && order_data.payment_mode !== "" && (
													<p className="mb-0">PAYMENT MODE: {order_data.payment_mode}</p>
												)} */}
												{order_data.status === 4 && (
													<p className="mb-1 text-danger">Order Status: Canceled</p>
												)}
											</p>
										</div>
									) : order_data!=null &&(
										<div>
											<div className="font-weight-bold text-uppercase h4 text-right">TAX Invoice</div>
											<div className="text-right">
											<p className="mb-5">
												DATE: {moment(order_data.timestamp * 1000).format("DD/MM/YYYY")}
												<br />
												TRN : {facility_data?.trn_no || ''}
												{/* TRN : 100361378100003 */}
												<br />
												INVOICE # {zeroPad(order_data.order_number, 6)}
												{order_data.payment_mode !== 0 && order_data.payment_mode !== "" && (
													<p className="mb-0">PAYMENT MODE: {order_data.payment_mode}</p>
												)}
												{order_data.status === 4 && (
													<p className="mb-1 text-danger">Order Status: Canceled</p>
												)}
											</p>
											</div>
										</div>
									)}
								</div>
								
								<div className="d-flex flex-row justify-content-between pt-2 pb-2">
									<div className="">


										{/* {facility_data!=null ? (facility_data.facility_address!=null ? <p className="mb-0">{facility_data.facility_address}</p>:""):""} */}
										
										<div className="font-weight-bold h5">{facility_data != null ? (facility_data.display_name != null ? <p className="mb-0">{facility_data?.display_name || ''} </p> : "") : ""}</div>
										{/* <div className="font-weight-bold h5">{facility_data != null ? (facility_data.facility_name != null ? <p className="mb-0">{facility_data.facility_name}</p> : "") : ""}</div> */}
										{facility_data != null ? (facility_data.facility_address != null ? <p className="mb-0">{facility_data.facility_address}</p> : "") : ""}
										{facility_data != null ? (facility_data.facility_phone != null ? <p className="mb-0">{facility_data.facility_phone}</p> : "") : ""}
										{facility_data != null ? (facility_data.facility_email != null ? <p className="mb-0">{facility_data.facility_email}</p> : "") : ""}
										{facility_data != null ? (facility_data.gstin != null ? <p className="mb-0">TRN: {facility_data.gstin}</p> : "") : ""}
										{/* {facility_data!=null ? (facility_data.license_number!=null ? <p className="mb-0">Lic No: {facility_data.license_number}</p>:""):""} */}
									</div>
								</div>
								
								<div className={`border-bottom pt-4 mb-5`} />

								{order_data != null ? <div className="d-flex flex-row justify-content-between mb-3 d-none">
									{/* <div className="d-flex flex-column w-50 mr-2 p-4 bg-semi-muted d-none">
										<div className="font-weight-bold h5">Subject:</div>
										<p className="mb-0">
											{order_data.customer_name}<br/>
											{order_data.billing_address!=null ? <Fragment>{order_data.billing_address}<br/></Fragment>:""}
											{order_data.customer_phone!=null ? <Fragment>Tel: {order_data.customer_phone}</Fragment>:""}
										</p>
									</div> */}
									<div className="d-flex w-100 flex-column p-1">
										<Table bordered className={`${styles.customBorder} ${styles.customTable}`}> {/* Apply the CSS Module Class */}
											<thead>
												<tr className={styles.tableHeader}> {/* Apply CSS Module Class for Header */}
													<th className="text-center">Customer Name</th>
													<th className="text-center">Mobile Number</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className="text-center">{order_data.customer_name || ''}</td>
													<td className="text-center">{order_data.customer_phone || ''}</td>
												</tr>
											</tbody>
										</Table>
										<div className={styles.bgLightBlue}> {/* Apply CSS Module for Customer TRN section */}
											<Fragment>CUSTOMER TRN : {order_data.vendor_trn || ''}</Fragment>
										</div>
									</div>

									{/* <div className="d-flex w-100 flex-column p-4 bg-semi-muted d-none">
										<div className="font-weight-bold h5">Customer Details</div>
										<p className="mb-0">
											{order_data.customer_name}<br />
											{order_data.shipping_address != null ? <Fragment>{order_data.shipping_address}<br /></Fragment> : ""}
											{order_data.customer_phone != null ? <Fragment>Tel: {order_data.customer_phone}</Fragment> : ""}
										</p>
									</div> */}
								</div> : ""}

								{order_data != null ? <Table bordered className={`${styles.customBorder} ${styles.customTable}`}>
									<thead>
										<tr className={styles.tableHeader}>
											{/* <th className="mb-2">#</th> */}
											<th className="mb-2">Description</th>

											<th className="mb-2 text-center">Quantity</th>
											{/* <th className="mb-2 text-right">Price</th> */}
											<th className="mb-2 text-center">Unit Price</th>
											<th className="mb-2 text-center">Amount</th>
											{is_igst ? <th className="mb-2 text-right">VAT</th> : <Fragment>
												<th className="mb-2 text-right">{is_utgst ? "UTGST" : "SGST"}</th>
												<th className="mb-2 text-right">CGST</th>
											</Fragment>}
											{/* <th className="mb-2 text-right">Discount</th> */}
											{/* <th className="mb-2 text-right">Serial No</th> */}

											<th className="mb-2 text-right">Total</th>
										</tr>
									</thead>
									<tbody>
										{order_products.map((row, index) => {
											sales_tax += parseFloat(row.product_sales_tax);
											sgst_tax += parseFloat(row.product_sgst_tax);
											cgst_tax += parseFloat(row.product_cgst_tax);
											p_discount += parseFloat(row.product_discount) + parseFloat(row.coupon_discount);

											let line_total = parseFloat(row.product_unit_price) * parseFloat(row.product_qty) + (parseFloat(row.product_excise_tax) + parseFloat(row.product_sales_tax) - parseFloat(row.product_discount) - parseFloat(row.coupon_discount))

											if (row.variation_id == order_data.discount_variation_id) {
												line_total -= parseFloat(order_data.manual_discount);
											}
											sub_total += parseFloat(row.product_unit_price) * parseFloat(row.product_qty);
											return (<tr key={index}>
												{/* <td>{index + 1}</td> */}
												<td>{row.product_name}</td>
												{/* <td>{row.product_name} {row.variation_name != null ? "(" + row.variation_name + ")" : ""}</td> */}

												<td className="text-center">{number_format(parseFloat(row.product_qty), 2, '.', '')}</td>
												<td className="text-right">{current_currency_symbol}{"" + number_format(parseFloat(row.product_unit_price), 2, '.', '')}</td>
												<td className="text-right">{current_currency_symbol}{""+number_format(parseFloat((parseFloat(row.product_unit_price)*parseFloat(row.product_qty)).toFixed(2)),2,'.','')}</td>
												{is_igst ? <td className="text-right">{current_currency_symbol}{"" + number_format(parseFloat(row.product_sales_tax), 2, '.', '')}</td> : <Fragment>
													<td className="text-right">{current_currency_symbol}{"" + number_format(parseFloat(row.product_sgst_tax), 2, '.', '')}</td>
													<td className="text-right">{current_currency_symbol}{"" + number_format(parseFloat(row.product_cgst_tax), 2, '.', '')}</td>
												</Fragment>}
												{/* <td className="text-right">{current_currency_symbol}{""}{row.variation_id!=order_data.discount_variation_id ? number_format(parseFloat(row.product_discount)+parseFloat(row.coupon_discount),2,'.',''):number_format(parseFloat(row.product_discount)+parseFloat(row.coupon_discount)+parseFloat(order_data.manual_discount),2,'.','')}</td> */}
												{/* <td className="text-right">{row.product_uniq_code}</td> */}

												<td className="text-right">{current_currency_symbol}{"" + number_format(line_total, 2, '.', '')}</td>
											</tr>)
										})}
									</tbody>
								</Table> : ""}
							</div>
							{order_data != null ? <div className="d-flex flex-column">
								<div className="border-bottom pt-3 mb-2" />
								<Table borderless className="d-flex justify-content-end">
									<tbody>
										<tr>
											<td className="pb-0">Subtotal :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sub_total, 2, '.', '')}</td>
										</tr>

										{is_igst ? <tr>
											<td className="pb-0">VAT :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sales_tax, 2, '.', '')}</td>
										</tr> : <Fragment>
											<tr>
												<td className="pb-0">{is_utgst ? "UTGST" : "SGST"} :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sgst_tax, 2, '.', '')}</td>
											</tr>
											<tr>
												<td className="pb-0">CGST :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(cgst_tax, 2, '.', '')}</td>
											</tr>
										</Fragment>}
										<tr>
											<td className="pb-0 ">Discount :</td>
											<td className="text-right pb-0 ">{current_currency_symbol}{"" + number_format(p_discount + parseFloat(order_data.auto_apply_discount) + parseFloat(order_data.coupon_discount) + parseFloat(order_data.manual_discount_value) + parseFloat(order_data.point_discount), 2, '.', '')}</td>
										</tr>
										{parseFloat(order_data.return_amount) != 0 ? <tr className="font-weight-bold">
											<td className="pb-0">Refund :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.return_amount), 2, '.', '')}</td>
										</tr> : ""}
										{order_data.return_date != 0 ? <tr className="font-weight-bold">
											<td className="pb-0">Grand Total :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total) - parseFloat(order_data.return_amount) - parseFloat(p_discount), 2, '.', '')}</td>
										</tr> :
											<tr className="font-weight-bold">
												<td className="pb-0">Grand Total :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total) - parseFloat(p_discount), 2, '.', '')}</td>
											</tr>}

										{this.props.credit == true ? <tr className="font-weight-bold">
											<td className="pb-0">Due Amount :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total) - parseFloat(p_discount), 2, '.', '')}</td>
										</tr> : ""}
									</tbody>
								</Table>
								<div className="border-bottom pt-2 mb-3" />
								<div className="d-flex flex-column w-100 border custom-border p-3">
									{/* Bank Account Details Header */}
									<div className="font-weight-bold">BANK ACCOUNT DETAILS</div>

									{/* Bank Details Text */}
									<div className="mt-2">
										<div>{facility_data?.display_name || ''}</div>
										<div>{facility_data?.bank_name || ''}</div>
										<div>ACCOUNT NO: {facility_data?.bank_account_no || ''}</div>
										<div>IBAN NO: {facility_data?.iban_no || ''}</div>
										<div>BRANCH: {facility_data?.branch || ''}</div>
									</div>

									{/* Customer Signature and Company Stamp Section */}
									<div className="d-flex justify-content-between align-items-center mt-4">
										<div className="font-weight-bold">CUSTOMER SIGNATURE</div>
										<div className="font-weight-bold">{facility_data?.display_name || ''}</div>
										{/* <img src={`${IMAGE_URL}assets/img/stamp.png`} alt="Company Stamp" width={80} height={80} /> */}
									</div>

									{/* Thank You Message */}
									<div className="w-100 text-center p-2 mt-3 bg-light-blue font-italic custom-border">
										Thank you for your business!
									</div>
								</div>
								{/* {typeof this.props.removeNotice == 'undefined' ? <p className="text-muted text-center mb-0">
									Payment by cheque payable to AL IKHTAYAR TRADING LLC<br />
									Cash cheque or Cash payment will be valid only against our official receipt<br />
									No refund on service and goods sold   {facility_data != null ? (facility_data.facility_email != null ? "<" + facility_data.facility_email + ">" : "") : ""}
								</p> : ""} */}
							</div> : ""}
						</CardBody>
					</div>
				</Colxx>
			</Row>
		);
	}
};
