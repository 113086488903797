import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import moment from 'moment';
//import DatePicker from "react-datepicker";
import DatePicker from "../../components/common/CustomDateTimePicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import { getCustomerWord } from "../../helpers/Utils";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const customerWord = getCustomerWord();

const selectData = [
    { label: "Normal", value: "1", key: 0 },
    { label: "Veterans", value: "2", key: 1 },
    { label: "Senior Citizens", value: "3", key: 2 },
    { label: "Student", value: "4", key: 3 },
    { label: "Inbound Customer", value: "5", key: 4 }];

class AddNewCustomerModal extends Component{

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			startTime: null,
			startDate: moment(),
			licenseExpirationDate: null,
			selectStateType: null,
			expirationDate: null,
			careDob: null,
			is_member_yes:true,
			is_member_yes_value:1,
			is_member_no:false,
			is_member_no_value:0,
			tax_exempt_yes:false,
			tax_exempt_yes_value:1,
			tax_exempt_no_value:2,
			tax_exempt_no:true,
			mmjCardExpDate: null,
			selectedOptions: { label: "Normal", value: "1", key: 0 },
			is_member:false,
			is_resident:false,
			is_senior:false,
			is_veteran:false,
			is_disabled:false,
			tax_exempt:false,
			is_employee:false,
			is_student:false,
			is_temporary_patient:false,
			same_as_vendor_name:true,
			trade_name:'',
			vendor_name:'',
			stateOptions:[],
			
			selectVendorType: null,
			selectRankType:null,
			vendorTypes:[],
			ranks:[],
			
			register_type:"2"
		};
	}
	
	componentDidMount() {		
		var stateOptions=[], vendorTypes=[], ranks=[];
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'wholesale/get-data-for-add-vendor',formData).then((res)=>{
			var data = res.data;
				
			if(data.states){
				stateOptions=data.states;			
				vendorTypes=data.partner_types;			
				ranks=data.ranks;
				
				let selectStateType = null;
				let selectVendorType = null;
				let selectRankType = null;
				
				stateOptions = stateOptions.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				if(stateOptions.length){
					selectStateType = stateOptions.find(r=>{ return r.value==13 });
				}
				
				vendorTypes = vendorTypes.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				if(vendorTypes.length){
					selectVendorType = vendorTypes[0];
				}
				
				ranks = ranks.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				if(ranks.length){
					selectRankType = ranks[0];
				}
				
				this.setState({
					stateOptions,
					vendorTypes,
					ranks,
					selectStateType,
					selectVendorType,
					selectRankType
				});
			}else{
				this.setState({
					stateOptions,
					vendorTypes,
					ranks,
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}

	handleChangeDate = date => {
		this.setState({
		  startDate: date
		});
	};
	
	handleChangeLicenseExpirationDate = date => {
		this.setState({
		  licenseExpirationDate: date
		});
	};

    handleExpirationDate = date => {
		this.setState({
		  expirationDate: date
		});
	};

    handleCareDob = date => {
		
		this.setState({
		  careDob: date
		});
	};

    handleMMJCardExpDate = date => {
		this.setState({
		  mmjCardExpDate: date
		});
	};

	handleChangeTime = time => {
		this.setState({
		  startTime: time
		});
	};

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			if(this.state.register_type=="2"){
				let dateOfBirth = document.getElementById("date_of_birth").value.trim();
				let expiryDate = document.getElementById("exp_date").value.trim(); 
				let caregiverDateOfBirth = document.getElementById("caregiver_date_of_birth").value.trim();
				let mmjCardExpiryDate = document.getElementById("caregiver_exp_date").value.trim();
				let errorMessage='';

				// if(dateOfBirth.length==0){
				// 	errorMessage+="Date of Birth is empty\n";
				// }
				
				/*if(caregiverDateOfBirth.length==0){
					errorMessage+="Caregiver Date of Birth is empty\n";
				}*/
				

				if(errorMessage.length!=0){
					swal("Warning",errorMessage,"warning");
					return false;
				}
			
				document.getElementById("update-customer").disabled=true;
				
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var formData = new FormData(document.getElementById("customerForm"));
				formData.append('facility_id',facility_id);
				formData.append('user_id',user_id);
				formData.append('is_vendor',0);
				
				formData.append('is_member',this.state.is_member ? 1:0);
				formData.append('is_resident',this.state.is_resident ? 1:0);
				formData.append('is_senior',this.state.is_senior ? 1:0);
				formData.append('is_veteran',this.state.is_veteran ? 1:0);
				formData.append('is_disabled',this.state.is_disabled ? 1:0);
				formData.append('tax_exempt',this.state.tax_exempt ? 1:0);
				formData.append('is_employee',this.state.is_employee ? 1:0);
				formData.append('is_student',this.state.is_student ? 1:0);
				formData.append('is_temporary_patient',this.state.is_temporary_patient ? 1:0);
				
				axios.post(REST_API_END_POINT+'customer/add-customer',formData).then((res)=>{	
						var data = res.data;
						if(data.status==1){
							var api_message=document.getElementById("api_message");
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
							
							window.setTimeout(function() {
								JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
									JQUERY_MODULE.$(this).remove();
								});
							}, 5000);
							
							JQUERY_MODULE.$('.alert .close').off("click",function(){}).on("click", function(e){
								JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
									JQUERY_MODULE.$(this).remove();
								});
							});
							
							document.getElementById("update-customer").disabled=false;
							
							let selectStateType = null;
							if(this.state.stateOptions.length){
								selectStateType = this.state.stateOptions[0];
							}
							
							this.setState({
								startTime: null,
								startDate: null,
								expirationDate: null,
								careDob: null,
								is_member_yes:true,
								is_member_yes_value:1,
								is_member_no:false,
								is_member_no_value:0,
								tax_exempt_yes:false,
								tax_exempt_yes_value:1,
								tax_exempt_no_value:2,
								tax_exempt_no:true,
								mmjCardExpDate: null,
								licenseExpirationDate: null,
								selectedOptions: { label: "Normal", value: "1", key: 0 },
								selectStateType,
								is_member:false,
								is_resident:false,
								is_senior:false,
								is_veteran:false,
								is_disabled:false,
								tax_exempt:false,
								is_employee:false,
								is_student:false,
								is_temporary_patient:false,
								register_type:"2"
							});
							
							this.props.toggleModal();
							this.props.dataListRender();
						}else{
							swal("Warning!",data.msg,'warning');
							document.getElementById("update-customer").disabled=false;
						}
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById("update-customer").disabled=false;
				});		
			}else{
				document.getElementById("update-customer").disabled=true;
			
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var formData = new FormData(document.getElementById("customerForm"));
				
				formData.append('facility_id',facility_id);
				formData.append('user_id',user_id);
				formData.append('same_as_vendor_name',this.state.same_as_vendor_name ? 1:0);
				formData.append('is_vendor',0);

				axios.post(REST_API_END_POINT+'wholesale/add-vendor',formData).then((res)=>{
					var data = res.data;
					document.getElementById("update-customer").disabled=false;
					if(data.status==1){
						var api_message=document.getElementById("api_message");
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);
						
						JQUERY_MODULE.$('.alert .close').on("click", function(e){
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						});
						
						this.props.toggleModal();
						let selectStateType = null;
						let selectVendorType = null;
						let selectRankType = null;
						
						if(this.state.stateOptions.length){
							selectStateType = this.state.stateOptions[0];
						}
						
						if(this.state.vendorTypes.length){
							selectVendorType = this.state.vendorTypes[0];
						}
						
						if(this.state.ranks.length){
							selectRankType = this.state.ranks[0];
						}
						
						this.setState({
							selectStateType,
							selectVendorType,
							selectRankType,
							register_type:"2",
							same_as_vendor_name:false,
							trade_name:'',
							vendor_name:'',
						});	
						this.props.dataListRender();
					}else{
						swal("Warning!",data.msg,'warning');
					}

				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById("update-customer").disabled=false;
				});
			}
		}
	}

	onChange = e => {
		switch (e.target.name) {
		  case 'license_img':
			if(e.target.files[0]){
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  case 'caregiver_license_img':
			if(e.target.files[0]){

				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  case 'vendor_obndd_certification':
				if(e.target.files[0]){
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
				}else{
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
				}
		  break;
		  default:
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		 }
	};


  render() {
		const { stateOptions, vendorTypes, ranks } = this.state;
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  parseInt(dataCrypto.decrypt(facility_id));
	  return (
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add New {customerWord}
		  </ModalHeader>
		  <AvForm
                id="customerForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
					{facility_id!=null ? <Row className="mb-2">
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Type of Profile</Label>
								<AvRadioGroup
									inline={true}
									name="register_type"
									validate={{
										required: {
											value: true,
											errorMessage: "Please select any of the profile type"
										}
									}}
									value={this.state.register_type}
								>	
									<AvRadio customInput label="Individual" value="2" onClick={e=>{ this.setState({ register_type:e.target.value }) }} />
									<AvRadio customInput label="Company" value="1" onClick={e=>{ this.setState({ register_type:e.target.value }) }} />
								</AvRadioGroup>
							</AvGroup>
						</Colxx>
					</Row>:""}
					
					{/*<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Vehicle Number<span className="text-danger">*</span></Label>
							  <AvField							
								name="vehicle_number"
								type="text"
								autoComplete="off"
								validate={{
									required: {
										value: true,
										errorMessage: "Please enter the Vehicle Number"
									},
									pattern: {
										value: "^[A-Z0-9]+$",
										errorMessage: "Invalid Vehicle Number"
									}
								}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Vehicle Make<span className="text-danger">*</span></Label>
							  <AvField							
								name="vehicle_make"
								type="text"
								autoComplete="off"
								validate={{
									required: {
										value: true,
										errorMessage: "Please enter the Vehicle Make"
									}
								}}
							  />
							</AvGroup>
						</Colxx>
					</Row>*/}
					
					<Row className="d-none">
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>GSTIN<span className="text-danger">*</span></Label>
							  <AvField							
								name="gstin"
								type="text"
								autoComplete="off"
								validate={{
									/*required: {
										value: true,
										errorMessage: "Please enter the GSTIN"
									},*/
									pattern: {
										value: "^[A-Z0-9]+$",
										errorMessage: "Invalid GSTIN"
									},
									minLength: {
										value: 15,
										errorMessage: "The GSTIN must be a 15 digits alpha-numeric number"
									},
									maxLength: {
										value: 15,
										errorMessage: "The GSTIN must be a 15 digits alpha-numeric number"
									}
								}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					
					{this.state.register_type=="2" ? <div>
						<Row className="d-none">
							<Colxx lg="12" className="mb-2">
								<h4 className="font-weight-bold">BASIC INFO</h4>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label><IntlMessages id="customer.name" /><span className="text-danger">*</span></Label>
								  <AvField
									id="customer_name"
									name="customer_name"
									type="text"
									autoComplete="off"
									validate={{
									  required: {
										value: true,
										errorMessage: "Please enter the first name"
									  },
									  pattern: {
										value: "^[a-zA-Z ,.'-]+$",
										errorMessage: "Invalid first name"
									  },
									  minLength: {
										value: 2,
										errorMessage:
										  "Your first name must be between 2 and 100 characters"
									  },
									  maxLength: {
										value: 100,
										errorMessage:
										  "Your first name must be between 2 and 100 characters"
									  }
									}}
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>EID No.</Label>
								  <AvField
									id="eid_no"
									name="eid_no"
									type="text"
									autoComplete="off"
									validate={{
									  pattern: {
										value: "^[a-zA-Z0-9 ,.-]+$",
										errorMessage: "Invalid EID No."
									  }
									}}
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6" className="d-none">
								<Label><IntlMessages id="customer.dob" /><span className="text-danger">*</span></Label>
								<DatePicker
									id="date_of_birth"
									name="date_of_birth"
									autoComplete="off"
									className="mb-2"
									maxDate={moment().subtract(18, "years")}
									selected={this.state.startDate}
									placeholderText=""
									dateFormat="yyyy-MM-dd"
									onChange={ this.handleChangeDate }
								  />
							</Colxx>
						</Row>
						
						<Row className="d-none">
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Gender</Label>
									<AvRadioGroup
										inline={true}
										name="gender"
										validate={{
											required: {
												value: true,
												errorMessage: "Please select any of the gender"
											}
										}}
										value={"M"}
									>	
										<AvRadio customInput label="Male" value="M" />
										<AvRadio customInput label="Female" value="F" />
										<AvRadio customInput label="Other" value="O" />
									</AvRadioGroup>
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label>Drivers License Number</Label>
								  <AvField
									id="license"
									name="license"
									type="text"
									autoComplete="off"								
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<Label>Drivers License Expiration Date</Label>
								<DatePicker
									id="license_exp"
									name="license_exp"
									autoComplete="off"
									className="mb-2"
									selected={this.state.licenseExpirationDate}
									placeholderText=""
									dateFormat="yyyy-MM-dd"
									onChange={ this.handleChangeLicenseExpirationDate }
								  />
							</Colxx>
						</Row>
						<AvGroup className="error-l-125 error-t-negative d-none">
							<Label><IntlMessages id="customer.license" /></Label>
								<InputGroup className="mb-3">
								  <InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
								  <CustomInput
									type="file"
									name="license_img"
									id="license_img"
									accept="image/*"
									onChange={this.onChange}
								  />
								</InputGroup>
						</AvGroup>
						<Row>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label>Mobile<span className="text-danger">*</span></Label>
								  <AvField
									id="phone"
									name="phone"
									type="text"
									autoComplete="off"
									validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											},
											pattern: {
												value: "^[0-9]{3}[0-9]{3}[0-9]{4}$",
												errorMessage: "Invalid phone number"
											}
										}}
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label>Whatsapp Number</Label>
								  <AvField
									id="whatsapp"
									name="whatsapp"
									type="text"
									autoComplete="off"
									validate={{
										pattern: {
											value: "^[0-9]{3}[0-9]{3}[0-9]{4}$",
											errorMessage: "Invalid whatsapp number"
										}
									}}
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label><IntlMessages id="customer.email" /></Label>
								  <AvField
									id="customer_email"
									name="customer_email"
									type="email"
									autoComplete="off"
									validate={{
									  
									  email: {
										value: true,
										errorMessage: "Please enter a valid email address"
									  }
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<AvGroup className="error-t-negative d-none">
							<Label><IntlMessages id="customer.notes" /></Label>	&nbsp;&nbsp;
							<AvField
								id="designation_notes"
								name="designation_notes"
								type="textarea"
								autoComplete="off"
								value={this.state.textarea_content}
								placeholder=""
								rows="3"
								validate={{
									minLength: {
										value: 5,
										errorMessage:"Your message must have minimum of 5 characters"
									},
								}}
							/>
						</AvGroup>
						<hr className="d-none" />
						<Row className="d-none">
							<Colxx lg="12" className="mb-2">
								<h4 className="font-weight-bold">Address</h4>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label>Street Address</Label>
								  <AvField
									id="customer_address"
									name="customer_address"
									type="text"
									autoComplete="off"
									
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label>City<span className="text-danger">*</span></Label>
								  <AvField
									id="customer_city"
									name="customer_city"
									type="text"
									autoComplete="off"
									
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<Label >State</Label>
								 <Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="vendor_state"
									id="vendor_state"
									value={this.state.selectStateType}						
									options={stateOptions}
									onChange={selectStateType => { this.setState({ selectStateType }) }}
								/>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label><IntlMessages id="customer.zip" /></Label>
								  <AvField
									id="customer_zip_code"
									name="customer_zip_code"
									type="text"
									min='0'
									autoComplete="off"
									validate={{
										maxLength: {
											value: 6,
											errorMessage:"Your pincode must be 6 characters"
										}
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						
						<hr className="d-none"/>
						<Row className="d-none">
							<Colxx lg="12" className="mb-2">
								<h4 className="font-weight-bold">STATUS</h4>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6" className="mb-2">
								<Label>													  
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_member}
										onChange={status => { this.setState({ is_member:!this.state.is_member }) }}
									/>
									<span className="h6 ml-4">Medical Member</span>
								</Label>
							</Colxx>
							<Colxx lg="6"  className="mb-2">
								<Label>													  
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_resident}
										onChange={status => { this.setState({ is_resident:!this.state.is_resident }) }}								
									/>
									<span className="h6 ml-4">Resident</span>
								</Label>					
							</Colxx>
						</Row>
						
						<Row className="d-none">
							<Colxx lg="6" className="mb-2">
								<Label>													  
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_veteran}
										onChange={status => { this.setState({ is_veteran:!this.state.is_veteran }) }}
									/>
									<span className="h6 ml-4">Veteran</span>
								</Label>
							</Colxx>
							<Colxx lg="6"  className="mb-2">
								<Label>													  
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_senior}
										onChange={status => { this.setState({ is_senior:!this.state.is_senior }) }}								
									/>
									<span className="h6 ml-4">Senior</span>
								</Label>					
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6" className="mb-2">
								<Label>													  
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_disabled}
										onChange={status => { this.setState({ is_disabled:!this.state.is_disabled }) }}
									/>
									<span className="h6 ml-4">Disabled</span>
								</Label>
							</Colxx>
							<Colxx lg="6"  className="mb-2">
								<Label>													  
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.tax_exempt}
										onChange={status => { this.setState({ tax_exempt:!this.state.tax_exempt }) }}								
									/>
									<span className="h6 ml-4">Tax Exempt</span>
								</Label>					
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6" className="mb-2">
								<Label>													  
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_employee}
										onChange={status => { this.setState({ is_employee:!this.state.is_employee }) }}
									/>
									<span className="h6 ml-4">Employee</span>
								</Label>
							</Colxx>
							<Colxx lg="6"  className="mb-2">
								<Label>													  
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_student}
										onChange={status => { this.setState({ is_student:!this.state.is_student }) }}								
									/>
									<span className="h6 ml-4">Student</span>
								</Label>
							</Colxx>
						</Row>
						<hr className="d-none"/>
						<Row className="d-none">
							<Colxx lg="12" className="mb-2">
								<h4 className="font-weight-bold">MEDICAL INFO</h4>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6">
								<AvGroup className="error-l-100 error-t-negative">
								  <Label>Med License Number</Label>
								  <AvField
									id="mmmj_number"
									name="mmmj_number"
									type="text"
									autoComplete="off"
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label>Med License Expires</Label>
								  <DatePicker
									id="exp_date"
									name="exp_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.expirationDate}
									placeholderText=""
									dateFormat="yyyy-MM-dd"
									onChange={ this.handleExpirationDate }
								  />
								</AvGroup>
							</Colxx>					
						</Row>
						<Row className="d-none">
							<Colxx lg="12" className="mb-2">
								<Label>													  
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.is_temporary_patient}
										onChange={status => { this.setState({ is_temporary_patient:!this.state.is_temporary_patient }) }}
									/>
									<span className="h6 ml-4">Temporary Patient</span>
								</Label>
							</Colxx>
						</Row>
						
						<Row className="d-none">
							<Colxx lg="6">
								<AvGroup className="error-l-100 error-t-negative">
								  <Label>Condition</Label>
								  <AvField
									id="patient_condition"
									name="patient_condition"
									type="text"
									autoComplete="off"
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-l-100 error-t-negative">
								  <Label>Physician</Label>
								  <AvField
									id="patient_physician"
									name="patient_physician"
									type="text"
									autoComplete="off"
								  />
								</AvGroup>
							</Colxx>				
						</Row>
						
						
						<Row className="d-none">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label><IntlMessages id="customer.caregiver" /></Label>
								  <AvField
									id="caregiver_name"
									name="caregiver_name"
									type="text"
									autoComplete="off"
									validate={{								 
									  pattern: {
										value: "^[a-zA-Z ,.'-]+$",
										errorMessage: "Invalid caregiver name"
									  },
									  minLength: {
										value: 2,
										errorMessage:
										  "Your caregiver name must be between 2 and 100 characters"
									  },
									  maxLength: {
										value: 100,
										errorMessage:
										  "Your caregiver name must be between 2 and 100 characters"
									  }
									}}
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<Label><IntlMessages id="customer.caredob" /></Label>
								<DatePicker
									id="caregiver_date_of_birth"
									name="caregiver_date_of_birth"
									className="mb-2"
									maxDate={moment().subtract(18, "years")}
									selected={this.state.careDob}
									placeholderText=""
									dateFormat="yyyy-MM-dd"
									onChange={ this.handleCareDob }
								  />
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label><IntlMessages id="customer.caremmjno" /></Label>
								  <AvField
									id="caregiver_mmmj_number"
									name="caregiver_mmmj_number"
									type="text"
									autoComplete="off"
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label><IntlMessages id="customer.expdatemmjcard" /></Label>
								  <DatePicker
									id="caregiver_exp_date"
									name="caregiver_exp_date"
									className="mb-2"
									selected={this.state.mmjCardExpDate}
									placeholderText=""
									dateFormat="yyyy-MM-dd"
									onChange={ this.handleMMJCardExpDate }
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<AvGroup className="error-l-125 error-t-negative d-none">
							<Label><IntlMessages id="customer.caregiverLicense" /></Label>
							<InputGroup className="mb-3">
							  <InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
							  <CustomInput
								type="file"
								name="caregiver_license_img"
								id="caregiver_license_img"
								accept="image/*"
								onChange={this.onChange}
							  />
							</InputGroup>
						</AvGroup>
						<hr className="d-none"/>
						<Row className="d-none">
							<Colxx lg="12" className="mb-2">
								<h4 className="font-weight-bold">OTHER</h4>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label><IntlMessages id="customer.employeeNumber" /></Label>
								  <AvField
									id="employee_agent_number"
									name="employee_agent_number"
									type="text"
									autoComplete="off"
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label><IntlMessages id="customer.hypurIsn" /></Label>
								  <AvField
									id="hypur_isn"
									name="hypur_isn"
									type="text"
									autoComplete="off"
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<AvGroup className="error-t-negative d-none">
							<Label><IntlMessages id="customer.typeOfCustomer" /></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"
								name="customer_type"
								id="customer_type"
								value={this.state.selectedOptions}
								options={selectData}
								onChange={selectedOptions => {this.setState({ selectedOptions:selectedOptions }) }}
							/>
						</AvGroup>
						
						  
					</div>:<div>				
						<Row>
							<Colxx lg="6" className="d-none">
								<AvGroup className="error-t-negative">
									<Label>Dispensary</Label>
									<AvField
										id="company_name"
										name="company_name"
										type="text"	
										autoComplete="off"
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Customer Name<span className="text-danger">*</span></Label>
									<AvField
										id="vendor_name"
										name="vendor_name"
										autoComplete="off"
										type="text"
										value={this.state.vendor_name}
										onChange={e=>{ this.setState({ vendor_name:e.target.value }); if(this.state.same_as_vendor_name){ this.setState({ trade_name:e.target.value }) } }}
										validate={{
										  required: {
											value: true,
											errorMessage: "Please enter the name"
										  },
										  minLength: {
											value: 2,
											errorMessage:"Your name must be between 2 and 150 characters"
										  },
										  maxLength: {
											value: 150,
											errorMessage: "Your name must be between 2 and 150 characters"
										  }
										}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="12">								
								<AvGroup className="error-t-negative">
									<Label>Trade Name<span className="text-danger">*</span></Label><br/>
									<Label>													  
										<Switch
											className="custom-switch custom-switch-primary im-inline-block"
											checked={this.state.same_as_vendor_name}
											onChange={status => { if(!this.state.same_as_vendor_name){ this.setState({ trade_name:this.state.vendor_name }) } this.setState({ same_as_vendor_name:!this.state.same_as_vendor_name }) }}
										/>
										<span className="h6 ml-4">Same as Contact Name</span>
									</Label>
									<AvField
										id="trade_name"
										name="trade_name"
										autoComplete="off"
										type="text"
										placeholder="Trade Name"
										disabled={this.state.same_as_vendor_name}
										value={this.state.trade_name}
										onChange={e=>{ this.setState({ trade_name:e.target.value }) }}
										validate={{
											required: {
												value: !this.state.same_as_vendor_name,
												errorMessage: "Please enter the trade name or select ”Same as Contact Name” "
											},
										}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label>Address<span className="text-danger">*</span></Label>
								  <AvField
									id="vendor_address"
									name="vendor_address"
									autoComplete="off"
									type="textarea"
									rows="2"
									
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label>City<span className="text-danger">*</span></Label>
								  <AvField
									id="vendor_city"
									name="vendor_city"
									autoComplete="off"
									type="text"
									
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6">
								<Label >State</Label>
								 <Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="state_id"
									id="state_id"
									value={this.state.selectStateType}						
									options={stateOptions}
									onChange={selectStateType => { this.setState({ selectStateType }) }}
								/>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label>Pincode<span className="text-danger">*</span></Label>
								  <AvField
									id="zip_code"
									name="zip_code"
									type="text"
									autoComplete="off"
									validate={{
										maxLength: {
											value: 6,
											errorMessage:"Your pincode must be 6 characters"
										}
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>EID No.</Label>
								  <AvField
									id="ea_eid_no"
									name="ea_eid_no"
									type="text"
									autoComplete="off"
									validate={{
									  pattern: {
										value: "^[a-zA-Z0-9 ,.-]+$",
										errorMessage: "Invalid EID No."
									  }
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="6">					
								<AvGroup className="error-t-negative">
								  <Label>Mobile<span className="text-danger">*</span></Label>
								  <AvField
									id="ea_phone"
									name="ea_phone"
									type="text"
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										pattern: {
											value: "^[0-9]{3}[0-9]{3}[0-9]{4}$",
											errorMessage: "Invalid phone number"
										}
									}}
								  />
								</AvGroup>
							</Colxx>
							
							<Colxx lg="6">					
								<AvGroup className="error-t-negative">
								  <Label>Whatsapp Number</Label>
								  <AvField
									id="ea_whatsapp"
									name="ea_whatsapp"
									type="text"
									autoComplete="off"
									validate={{
										pattern: {
											value: "^[0-9]{3}[0-9]{3}[0-9]{4}$",
											errorMessage: "Invalid whatsapp number"
										}
									}}
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Email</Label>
								  <AvField
									id="email"
									name="email"
									autoComplete="off"
									type="text"
									validate={{									  
									  email: {
										value: true,
										errorMessage: "Please enter a valid email address"
									  }
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6">					
								 <AvGroup className="error-t-negative">
								  <Label>License Number</Label>
								  <AvField
									id="license"
									name="license"
									autoComplete="off"
									type="text"
									
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6">						
								<AvGroup className="error-l-125 error-t-negative">
									<Label>License</Label>
									<InputGroup className="mb-3">
									  <InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
									  <CustomInput
										type="file"
										id="license_img"
										name="license_img"
										accept="image/*"
										onChange={this.onChange}
									  />
									</InputGroup>
								</AvGroup>					
							</Colxx>
						</Row>			
						<Row className="d-none">
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Notes</Label>
								  <AvField
									id="notes"
									name="notes"
									autoComplete="off"
									type="textarea"
									placeholder="Sample Notes"
									rows="2"
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="6">
								<Label >Vendor Type</Label>
								 <Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="partner_type"
									id="partner_type"
									value={this.state.selectVendorType}						
									options={vendorTypes}
									onChange={selectVendorType => { this.setState({ selectVendorType }) }}
								/>
							</Colxx>
							<Colxx lg="6" >
								<AvGroup className="error-t-negative">
								  <Label>Role</Label>
								  <AvField
									id="vendor_role"
									name="vendor_role"
									autoComplete="off"
									type="text"
									placeholder="Role"
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6" className="d-none">
								<Label >Rank</Label>
								 <Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="rank_id"
									id="rank_id"
									value={this.state.selectRankType}						
									options={ranks}
									onChange={selectRankType => { this.setState({ selectRankType }) }}
								/>
							</Colxx>
						</Row>
						
						<Row className="d-none">					
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Obndd Name</Label>
								  <AvField
									id="vendor_obndd_name"
									name="vendor_obndd_name"
									autoComplete="off"
									type="text"
								  />
								</AvGroup>
							</Colxx>
						</Row>
						
						<Row className="d-none">					
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label>Obndd #</Label>
								  <AvField
									id="vendor_obndd_no"
									name="vendor_obndd_no"
									autoComplete="off"
									type="text"
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6">						
								<AvGroup className="error-l-125 error-t-negative">
									<Label>Obndd Certification</Label>
									<InputGroup className="mb-3">
									  <InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
									  <CustomInput
										type="file"
										id="vendor_obndd_certification"
										name="vendor_obndd_certification"
										accept=".png,.jpg,.jpeg,.pdf"
										onChange={this.onChange}
									  />
									</InputGroup>
								</AvGroup>					
							</Colxx>
						</Row>
						</div>}
						<Row >					
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>TRN</Label>
								  <AvField
									id="vendor_trn"
									name="vendor_trn"
									autoComplete="off"
									type="text"
								  />
								</AvGroup>
							</Colxx>
						</Row>
					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="update-customer" >
					  <IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddNewCustomerModal;
